import { generateId, mustache } from '../minifield';
import Group from './group';
import { If } from '../tools/jsx-helper';

export const LinkType = 'link';
const Link = ({ component, id, variables={} }) => {
	const componentId = id ?? component.id ?? generateId(LinkType);
	const attributes = { ...component.attributes, ...variables.attributes };	
	const { caption, url } = mustache(component.properties, variables) ?? {};
	
	return (
		<a id={ componentId } aria-label={ caption } { ...attributes } href={ url }>
			<If condition={ component.components }>
				<Group component={ component.components } variables={ variables } />
			</If>
			<If condition={ caption }>
				{ caption }
			</If>
		</a>
	);
};
export default Link;