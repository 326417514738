import { useActions, useContent, BasicActions } from '../common/minifield';
import { LoginForm } from '../common/security/forms/login-form';
import { RegistrationForm } from '../common/security/forms/registration-form';
import { PasswordResetForm, PasswordResetRequestForm } from '../common/security/forms/password-reset-form';
import { MainPage } from './main-page';

const DefaultActions = () => {
	const action = useActions();
	const content = useContent();
	
	action.register('login', ({ action, payload }) => {
		content({
			title: 'Login',
			content: <MainPage><LoginForm action={ action } payload={ payload } /></MainPage>
		});
	});
	
	action.register('registration', (payload) => {
		content({
			title: 'Registration',
			content: <MainPage><RegistrationForm /></MainPage>
		});
	});
	
	action.register('password-reset-request', () => {
		content({
			title: 'Password Reset',
			content: <MainPage><PasswordResetRequestForm /></MainPage>
		});
	});
	
	action.register('password-reset', ({ request }) => {
		content({
			title: 'Password Reset',
			content: <MainPage><PasswordResetForm request={ request } /></MainPage>
		});
	});
	
	return <BasicActions />;
};
export { DefaultActions };