import './spinner.css';

const SpinnerItem = ({ level, colors }) => {
	if (level < 0) {
		return [];
	}
	return (
		<div className="multi-spinner" style={ {borderTopColor: colors[level]} }>
			<SpinnerItem level={ level-1 } colors={ colors } />
		</div>
	);
};

const Spinner = ({ colors, className }) => {
	if (!Array.isArray(colors)) {
		colors = ['orange', 'lightgray', 'darkgray', 'gray', 'black'];
	}

	return (
		<div className={ 'multi-spinner-container' + (className ? ' ' + className : '') }>
			<SpinnerItem level={ colors.length - 1 } colors={ colors } />
		</div>
	);
};	
export { Spinner };