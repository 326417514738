import React from 'react';
import { useActions, useContent } from '../minifield';

const LoginHandler = () => {
	const action = useActions();
	const content = useContent();
	
	React.useEffect(() => {
		action({ action: 'login' });
	});
	
	return content({ path: '/' });
};
export { LoginHandler };