const headerBuilder = (baseHeader) => {
	let header = {...baseHeader};
	
	const token = localStorage.getItem('auth_token');
	if (token) {
		header['Authorization'] = 'Bearer ' + token;
	}
	
	const builder = {
		build: () => {
			return header;
		},
		append: (other) => {
			if (other) {
				header = {...header, ...other};
			}
			return builder;
		},
		withContentType: (contentType) => {
			header['Accept'] = contentType;
			header['Content-Type'] = contentType;
			return builder;
		}
	};
	
	return builder;
};

const ContentProvider = (baseUrl, options = {}) => {
	const base = new URL(baseUrl);
	
	const handleError = (callback) => {
		return (error) => {
			if (callback && typeof(callback) === 'function') {
				callback(error);
			} else if (options && options.onError && typeof(options.onError) === 'function') {
				options.onError(error);
			} else {
				console.error(error.message);
			}
		};
	};
	
	const handleResponse = (response, errorHandler) => {
		if (response.status === 200) {
			return response.json();
		} else {
			errorHandler(response);
		}
	};
	
	const prepareURL = (url, data) => {
		const requestURL = new URL(base.pathname + url, base);

		if (options && 'parameters' in options) {
			data = { ...data, ...options.parameters };
		}

		if ( data ) {
			Object.entries(data).forEach(([key, value]) => {
				requestURL.searchParams.append(key, value);
			});
		}

		return requestURL;
	};
	
	return {
		get: async (url, data, customErrorHandler) => {
			const endpoint = prepareURL(url, data);
			return await fetch(endpoint, {
				method: 'GET',
				headers: headerBuilder(options['headers']).withContentType('application/json').build()
			}).then(response => handleResponse(response, customErrorHandler)).catch(handleError(customErrorHandler));
		},
		post: async (url, data, customErrorHandler) => {
			const endpoint = prepareURL(url);
			return await fetch(endpoint, {
				method: 'POST',
				headers: headerBuilder(options['headers']).withContentType('application/json').build(),
				body: JSON.stringify(data)
			}).then(response => handleResponse(response, customErrorHandler)).catch(handleError(customErrorHandler));
		},
		send: async (url, data, customErrorHandler) => {
			const endpoint = prepareURL(url);
			return await fetch(endpoint, {
				method: 'POST',
				headers: headerBuilder(options['headers']).build(),
				body: data
			}).then(response => handleResponse(response, customErrorHandler)).catch(handleError(customErrorHandler));
		},
		url: (url, data) => prepareURL(url, data),
		authenticationToken: (token) => {
			if (token === undefined) {
				return localStorage.getItem('auth_token');
			} else {
				token ? localStorage.setItem('auth_token', token) : localStorage.removeItem('auth_token');
				return token;
			}
		}
	};
};
export default ContentProvider;
