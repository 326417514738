const Empty = ({ children }) => {
	return [];
};
export { Empty };

const If = ({ condition, children }) => {
	if (condition) {
		return children;
	}
	return <Empty />;
};
export { If };