import React from 'react';
import moment from 'moment';
import { useActions, useApi, useDialog } from '../../common/minifield';
import { useNotification, Notification } from '../../common/tools/notifications';
import { If } from '../../common/tools/jsx-helper';

const DataImporter = ({ namespace }) => {
	const api = useApi();
	const dialog = useDialog();
	const [files, setFiles] = React.useState([]);
	const notification = useNotification({ type: 'inline', id: 'csv-import', className: 'mt-2' });
	const uploadBtn = React.useRef(null);
	
	const selectFiles = (fileList) => {
		const selectedFiles = [];
		for (let i = 0; i < fileList.length; i++) {
			selectedFiles[i] = fileList.item(i);
		}
		setFiles(selectedFiles);
		notification.hide();
		uploadBtn.current.disabled = false;
	};
	
	const cancel = React.useCallback((event) => {
		event.preventDefault();
		dialog({ options: { size: 'xl', visible: false } });
	}, [dialog]);
	
	const upload = React.useCallback((event) => {
		event.preventDefault();
		uploadBtn.current.disabled = true;
		const data = new FormData();
		files.forEach(file => {			
			data.append("files", file);
		});
		api.send('/admin/data/import/csv?namespace=' + namespace, data, (message) => notification.error(message)).then(response => {
			if (response.status === 'ERROR') {
				notification.error(response.message);
			} else {
				setFiles([]);
				notification.success(response.length + ' rows have been processed');
			}
		});
	}, [api, files, namespace, notification]);
	
	React.useEffect(() => {
		if (uploadBtn.current) {
			uploadBtn.current.disabled = true;
		}
	}, [uploadBtn]);
	
	return (
		<form>
			<p>Select CSV files to upload.</p>
			<input className="form-control" type='file' multiple onChange={ (event) => selectFiles(event.target.files) } />
			<Notification id='csv-import' />
			<ul className="list-group">
				<If condition={ files.length > 0 }>
					<li className="bg-secondary list-group-item bg-opacity-25 mt-2">
						<div className="row">
							<div className="col">Name</div>
							<div className="col-3">Modified</div>
							<div className="col-2">Type</div>
							<div className="col-2">Size</div>
						</div>
					</li>
				</If>
			{
				files.map((file, key) => (
					<li key={ key } className="list-group-item">
						<div className="row">
							<div className="col">{ file.name }</div>
							<div className="col-3">{ moment(file.lastModified).format('dddd, DD/MM/yyyy HH:mm') }</div>
							<div className="col-2">{ file.type }</div>
							<div className="col-2">{ file.size } bytes</div>
						</div>
					</li>
				))
			}
			</ul>
			<div className="d-flex justify-content-end mt-3">
				<button className="btn btn-outline-secondary me-2" onClick={ cancel }>Cancel</button>
				<button className="btn btn-primary" onClick={ upload } ref={ uploadBtn }>Upload</button>
			</div>
		</form>		
	);
};
export { DataImporter };

const DataImportActions = () => {
	const action = useActions();
	const dialog = useDialog();
	
	action.register('data-import', ({ namespace }) => {
		dialog({
			title: 'Import',
			content: <DataImporter namespace={ namespace } />,
			options: {
				size: 'xl',
				closable: false
			},
			buttons: []
		})
	});
	
	return [];
};
export { DataImportActions };