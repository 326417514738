import React from 'react';
import { createRoot } from 'react-dom/client';
import MinifieldAdmin from './minifield-admin';
import { Environment } from './common/context/environment-context';

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/index.css';
import './css/colours.css';

const root = createRoot(document.getElementById('content'));
root.render(
	<React.StrictMode>
		<Environment config="/config/environment">
			<MinifieldAdmin />
		</Environment>
	</React.StrictMode>
);