import React from 'react';

import List from '../../../common/components/list';
import { Spinner } from '../../../common/components/spinner';

import { useDataCache } from '../../data/data-cache';

const ticketComponent = {
	attributes: {
		className: 'list-group list-group-flush'
	},
	type: 'list',
	components: [{
		attributes: {
			className: 'list-group-item d-flex justify-content-between align-items-center'
		},
		type: 'group',
		components: [{
			type: 'text',
			properties: {
				markdown: '**{{account.name}}**\n\n{{#payment}}{{payment.properties.product.properties.name}}{{/payment}} - {{index}} / {{total}} {{^payment}}Free{{/payment}}\n\n{{status}} - {{modificationDate}}'
			}
		}, {
			attributes: {
				className: 'btn btn-sm btn-primary ms-2'
			},
			type: 'button',
			properties: {
				caption: 'View',
				action: 'data-view',
				payload: {
					namespace: '{{namespace}}',
					type: '{{type}}',
					id: '{{id}}'
				}
			}
		}]
	}],
	properties: {
		emptyMessage: {
			attributes: {
				className: 'list-group list-group-flush'
			},			
			type: 'group',
			components: [{
				attributes: {
					className: 'list-group-item'
				},
				type: 'text',
				properties: {
					text: 'No tickets found'
				}
			}]
		}
	}
};
const Tickets = ({ id, namespace, pagination, size, status }) => {
	const [filter] = React.useState(status ? { 'properties.status': status } : {});
	const tickets = useDataCache({ id: id ?? namespace + '/data/ticket#' + (status ? status : 'all'), namespace, type: 'ticket~payment|product', pagination, filter });
	
	if (!tickets) {
		return <Spinner lassName="mt-3" />;
	}
	
	return (
		<List component={ ticketComponent } data={ tickets } size={ size } />
	)
};
export { Tickets };