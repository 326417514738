import React from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Mustache from 'mustache';
import URI from 'urijs';

import Text from '../common/components/text';
import { Tabs } from '../common/components/tabs';
import { ApiContext } from '../common/context/api-context';
import { EnvironmentContext } from '../common/context/environment-context';
import { ReCaptchaContext } from '../common/security/recaptcha';
import { ActionMap } from '../common/tools/actions'
import messenger from '../common/tools/messenger'

const parseJson = (any) => {
	try {
		return JSON.parse(any);
	} catch(e) {
	}
	return any;
};

const useStoredState = (key, defaultValue) => {
	const [value, setValue] = React.useState(parseJson(localStorage.getItem(key)) ?? defaultValue);
	React.useEffect(()=> {
		if (value) {
			localStorage.setItem(key, JSON.stringify(value));
		} else {
			localStorage.removeItem(key);
		}
	}, [key, value]);
	return [value, setValue];
};
export { useStoredState };

const useEditedState = (originalValue) => {
    const [edited, setEdited] = React.useState(originalValue);
    return [
        edited,
        setEdited,
        (event) => {
            const name = event.target.name;
            const currentValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
            setEdited({ ...edited, [name]: currentValue });
            event.preventDefault();
        }
    ];
};
export { useEditedState };

const accountCache = (() => {
	const value = {
		data: undefined
	};	
	return {
		clear: () => value.data = undefined,
		setData: (data) => value.data = data,
		getData: () => value.data
	};
})();

const useAccount = () => {
	const api = useApi();
	const action = useActions();
	
	return React.useMemo(() => {
		const account = {
			obsolete: () => {
				accountCache.clear();
			},
			login: (token, callback) => {
				if (token) {
					api.authenticationToken(token);
					api.get('/account').then(userData => {
						if (userData && !userData.error) {
							accountCache.setData(userData);
							callback(userData);
						} else {
							account.logout();
							action({ action: 'login' });
						}
					}).catch((e) => {
						account.logout();
						action({ action: 'login' });
					});
				} else {
					account.logout();
					action({ action: 'login' });
				}
			},
			logout: () => {
				api.authenticationToken(null);
				accountCache.clear();
			},
			secured: (callback, x) => {
				const userData = accountCache.getData();
				if (userData) {
					callback(userData);
				} else {
					const token = api.authenticationToken();
					if (token) {
						account.login(token, callback);
					} else {
						action({ action: 'login', payload: x });
					}
				}
			},
			details: () => {
				const userData = accountCache.getData();
				if (userData) {					
					return { ...userData, authenticated: true };
				} else {
					return { authenticated: false };
				}
			}
		};
		return account;
	}, [api, action]);
};
export { useAccount };

const useActions = () => {
	const { publish } = useTopic('action');

	publish.register = (actionName, action) => ActionMap.put(actionName, action);

	return publish;
};
export { useActions };

const useApi = () => {
	return React.useContext(ApiContext);
};
export { useApi };

const cache = (name, timeout) => {	
	const meta = {
		value: undefined,
		date: moment()
	};
	
	const update = (value) => {
		meta.value = value;
		meta.date = moment();
		return value;
	};
	
	return {
		get: () => {
			if (meta.date.diff(moment()) > timeout) {
				update(undefined);
			}
			return meta.value;
		},
		set: (value) => update(value),
		clear: () => update(undefined)
	}
}
const caches = {};
const useCache = (name, timeout) => {
	const defaultTimeout = 5 * 60 * 1000;
	if (!(name in caches)){
		caches[name] = cache(name, timeout ?? defaultTimeout);
	}
	const _cache = caches[name];
	
	return (value) => value ? _cache.set(value) : _cache.get();
};
export { useCache };

const useContent = () => {
	const { publish } = useTopic('content');
	return publish;
};
export { useContent };

const useData = () => {
	const api = useApi();

	const toUri = (ref) => {
		return new URI(/\[ref: (.*)\]/.exec(ref)[1]);
	};

	const data = React.useMemo(() => {
		return {
			list: async (ref) => {
				const uri = toUri(ref);
				return await api.get(uri.path() + uri.search());
			},
			get: async (ref) => {
				const uri = toUri(ref);
				return await api.get(uri.path() + uri.hash().replace('#', '/'));
			}
		};
	}, [api]);

	return data;
};
export { useData };

const useDialog = () => {
	const { publish } = useTopic('dialog');

	publish.complete = React.useCallback(({ type, message, buttons, options, title, icon }) => {
		if (!icon) {
			switch (type) {
				case 'danger': {
					icon = <FontAwesomeIcon icon='fa-solid fa-circle-xmark' size='3x' className='text-danger mb-2' />;
					break;
				}
				default:
				case 'success': {
					icon = <FontAwesomeIcon icon="fa-solid fa-circle-check" size='3x' className='text-success mb-2'/>;
					break;
				}
				case 'warning': {
					icon = <FontAwesomeIcon icon='fa-solid fa-triangle-exclamation' size='3x' className='text-warning mb-2' />;
					break;
				}
			}
		}
		publish({
			title: title ?? 'Complete',
			content: (
				<div className="text-center">
					{ icon }
					<Text component={{
						properties: { markdown: message }
					}} />
				</div>
			),
			buttons,
			options
		});
	}, [publish]);

	return publish;
};
export { useDialog };

const useEnvironment = () => {
	return React.useContext(EnvironmentContext);
};
export { useEnvironment };

const useTabs = () => {
	const tabs = React.useRef({});

	return React.useMemo(() => {
		return {
			define: (key, content) => tabs.current[key] = content,
			render: (attributes = {}) => {
				const { className, id } = attributes;
				return <Tabs definition={ tabs.current } attributes={ { className, id } } />;
			}
		}
	}, [tabs]);
};
export { useTabs };

const useTopic = (name) => {
	return messenger.getTopic(name);
};
export { useTopic };

const useScript = (url) => {
	React.useEffect(() => {
		if (url) {
			const script = document.createElement('script');
			script.src = url;
			script.async = true;
			document.body.appendChild(script);
			return () => {
				document.body.removeChild(script);
			}
		}
	}, [url]);
};
export { useScript };

const useValidation = () => {
	return [];
};
export { useValidation };

const mustache = ( template, variables={} ) => {
	if (typeof template === 'string' || template instanceof String) {
		return Mustache.render(template, variables);
	} else if (typeof template === 'object') {
		return JSON.parse(Mustache.render(JSON.stringify(template), variables));
	}
	return template;
};
export { mustache };

const BasicActions = () => {
	const actions = useActions();

	actions.register('redirect', ({ target }) => window.location.href = target);

	return [];
};
export { BasicActions };

const generateId = (prefix, index) => {
	return prefix + '_' + (index ?? Date.now());
};
export { generateId };

const useReCaptcha = () => {
	return React.useContext(ReCaptchaContext) ?? { useToken: (action) => new Promise((resolve, reject) => resolve("")) };
};
export { useReCaptcha };

const useApiResponse = () => {
	const action = useActions();
	const responseHandler = React.useMemo(() => {
		return {
			handle: (response) => {
				if(response?.status === 'REDIRECT') {
					action({ action: 'redirect', payload: { target: response.target } });
				} else if (response?.status === 'TRIGGER') {
					action({ action: response.target, payload: response.body })
				}
			}
		}
	}, [action]);
	return responseHandler;
};
export { useApiResponse };

const ApiEndpointHandler = ({ context, version }) => {
	const location = useLocation();
	const api = useApi();
	const responseHandler = useApiResponse();

	api.get(location.pathname.replace(context + '/' + version, '') + location.search).then(responseHandler.handle);

	return [];
};
export { ApiEndpointHandler };