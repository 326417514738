import React from 'react';

import List from '../../../common/components/list';
import { Spinner } from '../../../common/components/spinner';
import { useDataCache } from '../../data/data-cache';

const lastPayments = {
	attributes: {
		className: 'list-group list-group-flush'
	},
	type: 'list',
	components: [{
		attributes: {
			className: 'list-group-item d-flex justify-content-between align-items-center'
		},
		type: 'group',
		components: [{
			type: 'text',
			properties: {
				markdown: '**{{account.name}}**\n\n{{product.properties.name}}\n\n{{status}} - {{creationDate}}'
			}
		}, {
			attributes: {
				className: 'd-flex flex-nowrap'
			},
			type: 'group',
			components: [{
				attributes: {
					className: 'btn btn-sm btn-outline-dark',
					target: '_blank'
				},
				type: 'link',
				properties: {
					caption: 'Stripe',
					url: 'https://dashboard.stripe.com/payments/{{stripe_payment}}'					
				}
			}, {
				attributes: {
					className: 'btn btn-sm btn-primary ms-2'					
				},
				type: 'button',
				properties: {
					caption: 'View',
					action: 'data-view',
					payload: {
						namespace: '{{namespace}}',
						type: '{{type}}',
						id: '{{id}}'
					}
				}			
			}]
		}]
	}],
	properties: {
		emptyMessage: {
			attributes: {
				className: 'list-group list-group-flush'
			},			
			type: 'group',
			components: [{
				attributes: {
					className: 'list-group-item'
				},
				type: 'text',
				properties: {
					text: 'No payments found'
				}
			}]
		}
	}
};
const LastPayments = ({ id = 'payment#all', namespace, pagination }) => {
	const payments = useDataCache({ id, namespace, type: 'payment~product', pagination });
	
	if (!payments) {
		return <Spinner attributes={ { className: 'mt-3' } } />;
	}
	
	return (
		<List component={ lastPayments } data={ payments } />
	)
};
export { LastPayments };