import React from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTopic } from '../minifield';
import { If } from '../tools/jsx-helper';

const useNotification = (props) => {
	const { type = 'banner', className = '', style = {}, id = ''} = props ?? {};
	const { publish } = useTopic('notification');
	return React.useMemo(() => {
		const base = { className, id, style, type, visible: true };
		return {
			error: (message, subject) => publish({ severity: 'error', message, subject, ...base }),
			warning: (message, subject) => publish({ severity: 'warning', message, subject, ...base }),
			success: (message, subject) => publish({ severity: 'success', message, subject, ...base }),
			info: (message, subject) => publish({ severity: 'info', message, subject, ...base }),
			hide: () => publish({ id, visible: false })
		};
	}, [className, id, publish, style, type]);	
};
export { useNotification };

const bootstrapSeverity = {
	error: 'bg-danger',
	warning: 'bg-warning',
	success: 'bg-success',
	info: 'bg-info'
};

const NotificationAlert = ({ id, subject, message, severity, className = '', style = {} }) => {
	const notification = useNotification({ id });
	return (
		<div className={ className + ' position-absolute w-100 z-1 py-2 px-3 d-flex justify-content-center ' + bootstrapSeverity[severity] } style={ {'--bs-bg-opacity': '0.85', ...style} } data-mf-id={ id }>
			<div className="text-center w-100"><ReactMarkdown children={ message } /></div>
			<button className="btn btn-link p-0" onClick={ () => notification.hide() } style={ {'color': 'inherit'} }>
				<FontAwesomeIcon icon="fa-solid fa-xmark" />
			</button>
		</div>
	);
};

const NotificationInline = ({ id, subject, message, severity, className = '', style = {} }) => {
	const notification = useNotification({ id });
	return (
		<div className={ className + ' d-flex p-1 rounded px-3 ' + bootstrapSeverity[severity] } style={ {'--bs-bg-opacity': '0.85', ...style} } data-mf-id={ id }>
			<div className="text-center w-100"><ReactMarkdown children={ message } /></div>
			<button className="btn btn-link p-0" onClick={ () => notification.hide() } style={ {'color': 'inherit'} }>
				<FontAwesomeIcon icon="fa-solid fa-xmark" />
			</button>
		</div>
	);
};

const NotificationToast = ({ id, message, subject, severity, className = '', style = {} }) => {
	const notification = useNotification({ id });
	return (
		<ToastContainer className={ className + ' z-1 m-1' } position='top-end' style={ { '--bs-bg-opacity': '0.85', ...style } } data-mf-id={ id }>
			<Toast className={ 'bg-opacity-75 ' + bootstrapSeverity[severity] }>
				<If condition={ subject }>
					<Toast.Header>
					</Toast.Header>
				</If>
				<If condition={ !subject }>
					<Toast.Header closeButton={ false } className="w-100 position-absolute border-0 d-flex justify-content-end p-1 pe-2" style={ { '--bs-toast-header-bg': 'rgba(0, 0, 0, 0.0)', 'color': 'inherit' } }>
						<button className="btn btn-link p-0" onClick={ () => notification.hide() } style={ {'color': 'inherit'} }>
							<FontAwesomeIcon icon="fa-solid fa-xmark" />
						</button>
					</Toast.Header>
				</If>
				<Toast.Body><ReactMarkdown children={ message } /></Toast.Body>
			</Toast>
		</ToastContainer>
	);	
};

const Notification = ({ id = '' }) => {
	const [notification, setNotification] = React.useState({ visible: false });
	const { subscribe } = useTopic('notification');
	
	subscribe(id, ({ id: target, className, message, severity, style, subject, type, visible }) => {
		if (id === target) {
			setNotification({ id, className, message, severity, style, subject, type, visible });
		}
	});
	
	if (notification.visible) {
		switch(notification.type) {
			default:
			case 'banner':
				return <NotificationAlert { ...notification } />
			case 'popup':
				return <NotificationToast { ...notification } />
			case 'inline':
				return <NotificationInline { ...notification } />
		}
	}
	
	return <div data-mf-id={ id}></div>;	
};
export { Notification };