import React from 'react';

import { useActions, useDialog } from '../../common/minifield';

import { DataViewer } from './data-viewer';
import { DataImportActions } from './data-import';

const DataActions = () => {
	const action = useActions();
	const dialog = useDialog();
	
	action.register('data-view', ({ namespace, type, id }) => {
		dialog({
			title: 'Details',
			content: <DataViewer namespace={ namespace } type={ type } id={ id } />,
			options: {
				size: 'xl'
			},
			buttons: []
		})
	});
	
	return <DataImportActions />;
};

export { DataActions };