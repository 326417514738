import React from 'react';
import { generateId } from '../minifield';
import { If } from '../tools/jsx-helper';
import ComponentRegistry from '../component-registry';

export const GroupType = 'group';
const Group = ({ component, id, variables={} }) => {	
	if (Array.isArray(component)) {
		component = {
			attributes: {},
			components: component,
			properties: {}
		}
	}
	
	const componentId = id ?? component.id ?? generateId(GroupType);
	const attributes = { ...component.attributes, ...variables.attributes };
	const items = component.components ?? [];
		
	return (
		<div id={ componentId } { ...attributes }>
			<If condition={ items.length > 0 }>
				{ 
					items.map((item, index) => ComponentRegistry.getRenderer(item.type).renderer(item, item.id ?? generateId(componentId, index), variables))
				}
			</If>
		</div>
	);
};
export default Group;