import React from 'react';
import { useApi, useActions, useEditedState } from '../../minifield';
import Button from '../../components/button';
import { If } from '../../tools/jsx-helper';

class ValidationResult {
	
	constructor(validationResult) {
		this.properties = { ...validationResult };
	};
	
	isValid = (key) => {
		if (key === undefined) {
			return Object.values(this.properties).reduce((total, current) => total && current, true);
		} else {
			return this.properties[key];
		}
	};
}

const RegistrationForm = () => {
	const action = useActions();
	const api = useApi();
	const [alert, setAlert] = React.useState('');
	const [formData, , handler] = useEditedState({});
	const [formValidation, setFormValidation] = React.useState(new ValidationResult({ name: true, email: true, password: true}));
	
	const registration = (event) => {
		event.preventDefault();
		
		const validationResult = new ValidationResult(validate(formData));
		setFormValidation(validationResult);
		if (validationResult.isValid()) {
			(async () => {
				setAlert('');
				const response = await api.post('/account/registration', formData);
				if (response) {
					if (response.status === 'ERROR') {
						setAlert(response.message);
					} else if (response.status === 'TRIGGER') {						
						action({ action: response.target, payload: response.body });
					}
				}
			})();		
		}			
	};
	
	const validate = (data) => {
		return {
			name: (data['name'] && data['name'].length > 0) || false,
			email: (data['email'] && data['email'].length > 0 && data['email'].match(/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g)) || false,
			password: (data['password'] && data['password'].length > 0 && data['password'] === data['password-confirm']) ||  false
		}
	};
	
	return (
		<>
			<div className="h3 text-center mb-3">Create an account</div>
			<If condition={ alert && alert !== ''}>
				<div className="alert alert-danger text-center p-1">{ alert }</div>
			</If>
			<div>
				<form autoComplete="on" onSubmit={ registration }>
					<div className="form-group mb-2">
						<label className="form-label">Name</label>
						<input type="text" name="name" className={ 'form-control ' + (formValidation.isValid('name') ? '' : 'is-invalid') } placeholder="Name" autoComplete="name" onChange={ handler }/>
						<div className="invalid-feedback" style={ { fontSize: '60%'} }>Name is required</div>
					</div>
					<div className="form-group mb-2">
						<label className="form-label">Email address</label>
						<input type="email" name="email" className={ 'form-control ' + (formValidation.isValid('email') ? '' : 'is-invalid') } placeholder="Email" autoComplete="email" onChange={ handler }/>
						<div className="invalid-feedback" style={ { fontSize: '60%'} }>Email is required</div>
						<span className="text-muted" style={ { fontSize: '60%'} }>We'll never share your personal data with anyone else.</span>
					</div>
					<div className="form-group mb-3">
						<label className="form-label">Password</label>
						<input type="password" name="password" className={ 'form-control ' + (formValidation.isValid('password') ? '' : 'is-invalid') } placeholder="Enter your password" autoComplete="new-password" onChange={ handler } />
						<div className="invalid-feedback" style={ { fontSize: '60%'} }>Password is required and should be matched</div>
						<input type="password" name="password-confirm" className={ 'form-control mt-2 ' + (formValidation.isValid('password') ? '' : 'is-invalid') } placeholder="Confirm password" autoComplete="confirm-password" onChange={ handler }/>
					</div>
					<div className="form-item">
						<button type="submit" className="btn btn-outline-dark w-100 mb-2" >Create</button>
					</div>
				</form>
				<div className="text-center">
					<Button component={ { type: 'link', properties: { caption: 'If you have an account, sign in', action: 'login', payload: {} }, attributes: { className: 'btn btn-link text-dark' } } } />
				</div>
			</div>
		</>
	);
};
export { RegistrationForm };