import React from 'react';

import { useActions } from '../../common/minifield';
import List from '../../common/components/list';
import { Spinner } from '../../common/components/spinner';
import { useDataCache } from './data-cache';
import { DataFilter } from './data-filter';

const event = {
	type: 'list',
	attributes: {
		className: 'mt-3'
	},
	components: [{
		type: 'group',
		attributes: {
			className: 'p-2 border rounded mb-1 d-flex justify-content-between align-items-center'
		},
		components: [
			{
				type: 'text',
				properties: {
					html: '<strong>{{intervalFmt}}</strong><span class="badge ms-2" data-mf-category="{{category}}">{{category}}</span><br />{{title}}<br />{{#tag}}<span class="badge bg-dark bg-opacity-25 me-1 text-dark">#{{.}}</span>{{/tag}}'
				}
			},
			{
				type: 'button',
				attributes: {							
					className: 'btn btn-sm btn-primary'
				},
				properties: {
					caption: 'View',
					action: 'data-view',
					payload: {
						namespace: '{{namespace}}',
						type: '{{type}}',
						id: '{{id}}'
					}
				}
			}
		]
	}]
};

const EventList = ({ portal }) => {
	const action = useActions();
	const filter = React.useState({ type: 'event', sort: 'properties.start,desc' });	
	return (
		<>
			<div className="d-flex justify-content-end">
				<button className="btn btn-link" type="button" onClick={ () => action({ action: 'data-import', payload: { namespace: portal.namespace } }) }>Import CSV</button>
			</div>
			<DataFilter filter={ filter } />
			<DataList component={ event } namespace={ portal.namespace } type={ filter[0].type } pagination={ { page: 0, size: 100, sort: filter[0].sort } } filter={ filter[0] } />		
		</>
	);
};
export { EventList };

const DataList = ({ component, namespace, type, pagination, filter }) => {
	const data = useDataCache({ id: namespace + '/data/' + type, namespace, type, pagination, filter });

	if (!data) {
		return <Spinner className="mt-3" />;
	}
	
	return (
		<List component={ component } data={ data } />
	);	
};

const UserDataList = ({ component, account, type, pagination }) => {
	const [filter] = React.useState({ badge: account.badge });
	const data = useDataCache({ id: type + "@" + account.badge, namespace: account.namespace, type, pagination, filter });

	if (!data) {
		return <Spinner className="mt-3" />;
	}
	
	return (
		<List component={ component } data={ data } />
	);
};
export { UserDataList };