import React from 'react';
import moment from 'moment';

const compareDates = (x, y) => moment(x).diff(moment(y));
const compareScalars = (x, y) => x - y;
const compareTexts = (x, y) => x.localeCompare(y);

const usePropertyComparator = ({ type, field, direction }) => {
	return React.useCallback((x, y) => {
		const multiplier = direction === 'desc' ? -1 : 1;
		if (field === undefined) {
			return compareScalars(0, 0); 
		}
		switch (type) {
			case 'date':
				return compareDates(x.properties[field], y.properties[field]) * multiplier;
			case 'text':
				return compareTexts(x.properties[field], y.properties[field]) * multiplier;
			default:
			case 'number':
				return compareScalars(x.properties[field], y.properties[field]) * multiplier;
		}
		
	}, [type, field, direction]);
};
export { usePropertyComparator };

const usePropertyFilter = ({ type, field, operator, value }) => {
	return React.useCallback((x) => {
		if (field === undefined) {
			return true;
		}
		switch(type) {
			case 'date':
				switch(operator) {
					default:
					case 'greaterThan':
						return moment(x.properties[field]).isAfter(moment(value));
				}
			case 'text':
				switch(operator) {
					default:
					case 'equals':			
						return x.properties[field] === value;
				}
			default:
			case 'number':
				switch(operator) {
					default:
					case 'greaterThan':
						return x.properties[field] > value;
				}
		}
	}, [type, field, operator, value]);
};
export { usePropertyFilter };