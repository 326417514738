import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateId, mustache } from '../minifield';

export const MediaType = 'media';
const Media = ({ component, id, variables={} }) => {
	const componentId = id ?? component.id ?? generateId(MediaType);
	const attributes = { ...variables.attributes, ...component.attributes };
	const { icon, picture, video, name, description, dataURI } = mustache(component.properties, variables) ?? {};
	
	if (icon) {
		return (
			<FontAwesomeIcon id={ componentId } icon={ icon } { ...attributes } />
		);
	}
	
	if (dataURI) {
		return (
			<img id={ componentId } src={ dataURI } { ...attributes } alt={ description } title={ name } />
		);
	}
	
	if (video) {
		return (
			<span id={ componentId } { ...attributes }></span>
		);
	}

	if (picture) {
		return (
			<picture>
				<img id={ componentId } src={ picture } { ...attributes } alt={ description } title={ name } />
			</picture>
		);
	}
	
	return [];
};
export default Media;