import ReactMarkdown from 'react-markdown';
import { mustache, generateId } from '../minifield';

export const TextType = 'text';
const Text = ({ component, id, variables={} }) => {
	const componentId = id ?? component.id ?? generateId(TextType);
	const attributes = { ...component.attributes, ...variables.attributes };
	const { markdown, text, html } = component.properties ?? {};
	
	if (markdown) {
		return (
			<div id={ componentId } { ...attributes } key={ componentId }>
				<ReactMarkdown children={ mustache(markdown, variables) } />
			</div>
		);
	}

	if (html) {
		return (
			<div id={ componentId } { ...component.attributes } key={ componentId }><span dangerouslySetInnerHTML={{ __html: mustache(html, variables) }}></span></div>
		);
	}
	
	return (
		<div id={ componentId } { ...component.attributes } key={ componentId }>{ mustache(text, variables) }</div>
	);
};
export default Text;
