import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useActions, useDialog, useTabs } from '../../common/minifield';
import List from '../../common/components/list';
import { If } from '../../common/tools/jsx-helper';

import { useAccountCache } from '../account/account-cache';
import { useDataCache } from './data-cache';

const GeneralDataProperties = ({ data }) => {
	const accounts = useAccountCache({ namespace: data.namespace });
	const action = useActions();
	const [owner, setOwner] = React.useState();
	
	React.useEffect(() => {
		if (accounts) {
			const account = accounts.find(x => x.badge === data.badge);
			if (account) {
				setOwner(account.name);
			}
		}
	}, [data, accounts]);
	
	return (
		<div className="pt-3">
			<div className="form-group mb-2">
				<label className="form-label">Id</label>
				<input type='text' className="form-control" value={ data.id } readOnly />
			</div>
			<div className="form-group mb-2 row">
				<div className="col">
					<label className="form-label">Type</label>
					<input type='text' className="form-control" value={ data.type } readOnly />
				</div>				
				<If condition={ data.properties?.status }><div className="col">
					<label className="form-label">Status</label>
					<input type='text' className="form-control" value={ data.properties?.status } readOnly />
				</div></If>
			</div>
			<div className='form-group mb-2'>
				<label className='form-label'>Namespace</label>
				<input type='text' className='form-control' value={ data.namespace } readOnly />
			</div>
			<If condition={ 'badge' in data }>
				<div className='form-group mb-2'>
					<label className='form-label'>Owner</label>
					<div className="d-flex align-items-center">
						<input type='text' className='form-control' value={ owner } readOnly />
						<button type="button" className="btn btn-sm btn-dark ms-2" onClick={ (event) => action({ action: 'account-view', payload: { namespace: data.namespace, badge: data.badge } }) } ><FontAwesomeIcon icon="fa-solid fa-user" /></button>
					</div>
				</div>
			</If>
			<If condition={ 'creationDate' in data }>
				<div className='row'>
					<div className='form-group mb-2 col'>
						<label className='form-label'>Creation date</label>
						<input type='text' className='form-control' value={ moment.utc(data.creationDate).format('dddd, DD/MM/yyyy HH:mm') } readOnly />
					</div>
					<div className='form-group mb-2 col'>
						<label className='form-label'>Modification date</label>
						<input type='text' className='form-control' value={ moment.utc(data.modificationDate).format('dddd, DD/MM/yyyy HH:mm') } readOnly />
					</div>
				</div>
			</If>
			<div className="form-group">
				<label className="form-label">Properties</label>
				<div className="form-control">
					<div className="row">
						<div className="col-4"><strong>Key</strong></div><div className="col-8"><strong>Value</strong></div>				
					</div>
					{
						Object.entries(data.properties).map(([key, value]) => <div className="row" key={ key }><div className="col-4">{ key }</div><div className="col-8 text-break">{ JSON.stringify(value) }</div></div>)
					}
				</div>
			</div>
		</div>
	);
};

const ParticipantList = ({ id, namespace }) => {
	const accounts = useAccountCache({ namespace });
	const bookings = useDataCache({ namespace, type: 'booking' });
	const [items, setItems] = React.useState();
	const [count, setCount] = React.useState(0);
	
	React.useEffect(() => {
		if (!accounts || !bookings) {
			return;
		}
		
		const data = bookings.filter(x => x.properties.target.includes(id)).map((booking, index) => {
			booking.properties.index = index+1;
			booking.properties.creationDate = moment.utc(booking.creationDate).format('dddd, DD/MM/yyyy HH:mm');
			booking.properties.modificationDate = moment.utc(booking.modificationDate).format('dddd, DD/MM/yyyy HH:mm');
			const account = accounts.find(x => x.badge === booking.badge);
			if (account) { 
				booking.properties.username = account.name;
			}
			switch (booking.properties?.status) {
				case 'COMPLETED':
					booking.properties.bs_status = 'success';
					break;
				case 'CANCELLED':
					booking.properties.bs_status = 'warning';
					break;
				case 'PENDING':
					booking.properties.bs_status = 'info';
					break;
				default:
					booking.properties.bs_status = 'danger';
					break;
			}
			return booking;
		});
		setItems(data);
		setCount(data.filter(x => x.properties.status === 'COMPLETED').length);
	}, [id, bookings, accounts]);
	
	const component = {
		type: 'list',
		components: [{
			type: 'text',
			properties: {
				html: '<div class="p-1 mb-1 alert alert-{{bs_status}} d-flex"><div class="col-1">{{index}}</div><div class="col">{{username}}{{#name}} ({{name}}){{/name}}</div><div class="col">{{status}}</div><div class="col d-none d-lg-block">{{creationDate}}</div><div class="col">{{modificationDate}}</div></div>'
			}				
		}]		
	};
	
	return (
		<div className="pt-3">
			<List component={ component } data={ items } emptyMessage={ { type: 'group', components: [{ attributes: { className: 'border p-2 rounded text-center mb-1' }, type: 'text', properties: { text: 'There is no any booking for this event yet.' } }] } } />
			<form>
				<div className="form-group">
					<label className="form-label col">Number of participants</label>
					<input className="form-control col" type="text" readOnly disabled value={ count } />
				</div>
			</form>
		</div>
	);
};

const EventView = ({ data }) => {
	const dialog = useDialog();
	const tabs = useTabs();
	const [content, setContent] = React.useState();
	
	React.useEffect(() => {
		if (data) {
			tabs.define('General', () => <GeneralDataProperties data={ data } />);
			if (data.type === 'event') {
				tabs.define('Participants', () => <ParticipantList id={ data.id } namespace={ data.namespace } />);
			}
			setContent(tabs.render());
		}	
	}, [data, tabs]);	
	
	return (
		<div>
			{ content }
			<div className="d-flex justify-content-end mt-3">
				<button type="button" className="btn btn-dark" onClick={ (e) => dialog({ options: { size: 'xl', visible: false } }) }>Cancel</button>
			</div>
		</div>
	);
};
export { EventView };