import React from 'react';
import { generateId, mustache, useTopic } from '../minifield';
import Group from './group';
import { If } from '../tools/jsx-helper';

export const ButtonType = 'button';
const Button = ({ component, id, variables={} }) => {
	const componentId = id ?? component.id ?? generateId(ButtonType);
	const attributes = { ...component.attributes, ...variables.attributes };	
	const { action, caption, payload } = mustache(component.properties, variables) ?? {};
	const { publish } = useTopic('action');
	
	return (
		<button id={ componentId } aria-label={ caption } { ...attributes } onClick={ () => publish({ source: componentId, action: action, payload: payload }) }>
			<If condition={ component.components }>
				<Group component={ component.components } variables={ variables } />
			</If>
			<If condition={ caption }>
				{ caption }
			</If>
		</button>
	);
};
export default Button;