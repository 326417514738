import React from 'react';

import Group from './group';
import { Spinner } from './spinner';

import { useApi, useTopic } from '../minifield';

const baseTitle = document.title;

const Content = (props) => {
	const api = useApi();
	const [title, setTitle] = React.useState();
	const [loading, setLoading] = React.useState(true);
    const [content, setContent] = React.useState({ path: '/', page: undefined });	
	const {subscribe} = useTopic('content');
	
    subscribe('Content', ({ title, path, content, options }) => {
		if (content) {
			if ('components' in content) {
                setContent({ path: content.path, page: <Group component={ content.components } /> });
            } else {
                setContent({ path: undefined, page: content });
			}
			setLoading(false);
        }
        if (title) {
            setTitle(title);
        }
		if (path) {
			api.get('/page/view', {
				path: path
			}).then(response => {
				setContent({ path: path, page: (response ? <Group component={ response } /> : undefined) });
				setTitle(response ? response.properties?.title || '' : '');
				setLoading(false);
			});
		}
		changeOptions(options);
	});
	
	const changeOptions = (newOptions) => {
	};

	if (loading) {
		return <div className='mt-5'><Spinner /></div>;
	}
	
	if (!content.page) {
		return (
			<div className="mf-http-error">
				<h1 className="text-center">404</h1>
				<p className="text-center text-muted small">Not found!</p>
				<p className="text-center display-5 mx-4">Oops! The requested page does not exist.</p>
			</div>
		);
	} else {
		document.title = baseTitle + (title ? ' - ' + title : '');
		return (
			<div {...props}>
				{ content.page }
			</div>
		);
	}
};
export default Content;