const defaultRenderer = (component, id, variables) => undefined;
class ComponentRegistry {
	registry = {};
	
	constructor () {
		if (!ComponentRegistry.instance) {
			ComponentRegistry.instance = this
		}
		return ComponentRegistry.instance
	};
	
	register = (type, renderer) => {
		this.registry[type] = renderer;
	};
	
	get = (type) => {
		return this.registry[type];
	};
	
	getRenderer = (type) => {
		return {
			renderer: this.registry[type] ?? defaultRenderer
		}
	};
};

const instance = new ComponentRegistry();
export default instance;