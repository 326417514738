import React from 'react';
import { useApi, useActions, useDialog, useEditedState } from '../../minifield';
import Button from '../../components/button';
import { decode } from '../../tools/b64';
import { If } from '../../tools/jsx-helper';

class ValidationResult {
	
	constructor(validationResult) {
		this.properties = { ...validationResult };
	};
	
	isValid = (key) => {
		if (key === undefined) {
			return Object.values(this.properties).reduce((total, current) => total && current, true);
		} else {
			return this.properties[key];
		}
	};
}

const PasswordResetRequestForm = () => {
	const api = useApi();
	const dialog = useDialog();
	const [alert, setAlert] = React.useState('');
	const [formData, , handler] = useEditedState({});
	const [formValidation, setFormValidation] = React.useState(new ValidationResult({ name: true, email: true, password: true}));
	
	const sendPasswordResetEmail = (event) => {
		event.preventDefault();
		
		const validationResult = new ValidationResult(validate(formData));
		setFormValidation(validationResult);
		if (validationResult.isValid()) {
			setAlert('');
			api.get('/account/password-reset-request', formData).then(response => {
				if (response) {
					if (response.status === 'ERROR') {
						setAlert(response.message);
					} else {
						dialog({
							title: '',
							content: {
								components: [{
									type: 'text',
									properties: {
										text: 'If we found your email address in our database we have sent you an email with the password reset link.'
									}
								}]
							},
							buttons: [{
								caption: 'Close',
								callback: (modal) => modal.hide()
							}]
						});
					}
				}
			});	
		}			
	};
	
	const validate = (data) => {
		return {
			email: (data['email'] && data['email'].length > 0 && data['email'].match(/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g)) || false
		}
	};
	
	return (
	    <>
			<div className="h3 text-center mb-3">Change your password</div>
			<div>
				<If condition={ alert && alert !== ''}>
					<div className="alert alert-danger text-center p-1">{ alert }</div>
				</If>
				<p>If you want to change your password, enter your email address and click the <strong>Send email</strong> button.</p>
				<p>We will send you an email with a password reset link and the further instructions.</p>
				<form autoComplete="on" onSubmit={ sendPasswordResetEmail }>
					<div className="form-group mb-2">
						<label className="form-label">Email address</label>
						<input type="email" name="email" className={ 'form-control ' + (formValidation.isValid('email') ? '' : 'is-invalid') } placeholder="Email" autoComplete="email" onChange={ handler }/>
						<div className="invalid-feedback" style={ { fontSize: '60%'} }>Email is required</div>
					</div>
					<div className="form-item">
						<button type="submit" className="btn btn-outline-dark w-100 mb-2" >Send email</button>
					</div>
				</form>
				<div className="text-center">
					<Button component={ { type: 'link', properties: { caption: 'Back to sign in', action: 'login', payload: {} }, attributes: { className: 'btn btn-link text-dark' } } } />
				</div>
			</div>
		</>
	);
};
export { PasswordResetRequestForm };

const PasswordResetForm = ({ request, badge }) => {
	const action = useActions();
	const api = useApi();
	const dialog = useDialog();
	
	const requestObj = request ? JSON.parse(decode(request)) : { badge };
	const [alert, setAlert] = React.useState('');
	const [formData, , handler] = useEditedState({ ...requestObj });
	const [formValidation, setFormValidation] = React.useState(new ValidationResult({ name: true, email: true, password: true}));
	
	const setPassword = (event) => {
		event.preventDefault();
		
		const validationResult = new ValidationResult(validate(formData));
		setFormValidation(validationResult);
		if (validationResult.isValid()) {
			setAlert('');
			dialog({
				options: {
					disabled: true
				}
			});
			api.post('/account/password-reset', formData).then(response => {
				if (response) {
					if (response.status === 'ERROR') {
						dialog.complete({
							type: 'danger',
							title: 'Error',
     						message: '# Something went wrong during the password change',
							buttons: [{
								caption: 'Close',
								callback: (modal) => modal.hide()
							}]
						});
					} else {
						dialog.complete({
							title: ' ',
     						message: '# Congratulation\n\nYour password has been changed successfully',
							buttons: [{
								caption: 'Login',
								callback: (modal) => action({ action: 'profile' })
							},{
								caption: 'Close',
								callback: (modal) => modal.hide()
							}]
						});
					}
				}
			});
		}			
	};
	
	const validate = (data) => {
		return {
			password: (data['password'] && data['password'].length > 0 && data['password'] === data['password-confirm']) ||  false
		}
	};
	
	return (
		<>
			<div className="h3 text-center mb-3">Change your password</div>
			<div>
				<If condition={ alert && alert !== ''}>
					<div className="alert alert-danger text-center p-1">{ alert }</div>
				</If>
				<form autoComplete="on" onSubmit={ setPassword }>
					<div className="form-group mb-3">
						<label className="form-label">Email address</label>
						<input type="email" name="email" className="form-control" readOnly disabled value={ requestObj.email } />
					</div>
					<div className="form-group mb-3">
						<label className="form-label">Set password</label>
						<input type="password" name="password" className={ 'form-control ' + (formValidation.isValid('password') ? '' : 'is-invalid') } placeholder="Enter your new password" autoComplete="new-password" onChange={ handler } />
						<div className="invalid-feedback" style={ { fontSize: '60%'} }>Password is required and should be matched</div>
						<input type="password" name="password-confirm" className={ 'form-control mt-2 ' + (formValidation.isValid('password') ? '' : 'is-invalid') } placeholder="Confirm password" autoComplete="confirm-password" onChange={ handler }/>
					</div>
					<div className="form-item">
						<button type="submit" className="btn btn-outline-dark w-100 mb-2" >Set password</button>
					</div>
				</form>
				<div className="text-center">
					<Button component={ { type: 'link', properties: { caption: 'Back to sign in', action: 'login', payload: {} }, attributes: { className: 'btn btn-link text-dark' } } } />
				</div>
			</div>
		</>
	);	
};
export { PasswordResetForm };