import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AuthenticationHandler } from '../common/security/authentication-handler';
import { PasswordResetHandler } from '../common/security/password-reset-handler';
import { LoginHandler } from '../common/security/login-handler';
import { LogoutHandler } from '../common/security/logout-handler';

import { useContent } from '../common/minifield';

import { PortalSelectorHandler } from './portal/portal-selector';
import { DefaultActions } from './default-actions';
import { PortalActions } from './portal/portal-actions';
import { AccountActions } from './account/account-actions';
import { DataActions } from './data/data-actions';

const AdminPortalContent = () => {
	return (
		<>
			<DefaultActions />
			<PortalActions />
			<AccountActions />
			<DataActions />
			<BrowserRouter>
				<Routes>
					<Route path="/login" element={ <LoginHandler /> }></Route>
					<Route path="/logout" element={ <LogoutHandler /> }></Route>
					<Route path="/password-reset" element={ <PasswordResetHandler /> }></Route>
					
					<Route path="/auth/*" element={ <AuthenticationHandler /> }></Route>					
					
					<Route path="/" element={ <PortalSelectorHandler /> }></Route>
					<Route path='*' element={ <ContentHandler /> }></Route>
				</Routes>
			</BrowserRouter>
		</>
	);
};
export { AdminPortalContent };

const ContentHandler = () => {
	const content = useContent();
	
	content({ path: document.location.pathname });
	
	return [];
};