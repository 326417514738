import React from 'react';

import { useAccountCache } from './account-cache.js';
import List from '../../common/components/list';
import { Spinner } from '../../common/components/spinner';
import { usePropertyComparator } from '../../common/tools/data-helper';

const component = {
	attributes: {
		className: 'mt-3'
	},
	type: 'list',
	components: [{
		attributes: {
			className: 'p-2 border rounded mb-1 d-flex justify-content-between align-items-center'
		},
		type: 'group',
		components: [{
			type: 'text',
			properties: {
				html: '<h3 title="{{badge}}">{{name}}</h3>{{email}}'
			}
		}, {
			attributes: {
				className: 'btn btn-sm btn-primary'
			},
			type: 'button',
			properties: {
				caption: 'View',
				action: 'account-view',
				payload: {
					namespace: '{{namespace}}',
					badge: '{{badge}}'
				}
			}
		}]
	}],
	properties: {
		comparator: {
			field: 'name',
			type: 'text'
		}
	}
};

const AccountList = ({ portal }) => {
	const accountCache = useAccountCache({ namespace: portal.namespace });
	const comparator = usePropertyComparator({ field: 'name', type: 'text' });
	
	const prepareItems = (item) => {
		item.id = item.badge;
		item.properties = {
			badge: item.badge,
			namespace: item.namespace,
			name: item.name,
			email: item.email
		};
		return item;
	};
	
	if (!accountCache) {
		return <Spinner attributes={ { className: 'mt-3' } } />;
	}

	return (
		<List component={ component } data={ accountCache.map(prepareItems).sort(comparator) } />
	);
};
export { AccountList };