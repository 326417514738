import React from 'react';
import { useApi } from '../../common/minifield';
import { EventView } from './event';

const DataViewer = ({ namespace, type, id }) => {
	const api = useApi();
	const [data, setData] = React.useState();
	
	React.useEffect(() => {		
		api.get('/admin/data/view', { namespace, type, id }).then(result => setData(result));
	}, [api, namespace, type, id]);
	
	return (
		<EventView data={ data } />
	);
};
export { DataViewer };