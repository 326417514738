import React from 'react';

import { useActions, useDialog } from '../../common/minifield';

import { AccountViewer } from './account-viewer';

const AccountActions = () => {
	const action = useActions();
	const dialog = useDialog();
	
	action.register('account-view', ({ namespace, badge }) => {
		dialog({
			title: 'Account',
			content: <AccountViewer namespace={ namespace } badge={ badge } />,
			options: {
				size: 'xl'
			},
			buttons: []
		})
	});
	
	return [];
};

export { AccountActions };