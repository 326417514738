import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAccount, useActions, useTabs } from '../../common/minifield';
import { If } from '../../common/tools/jsx-helper';

import { Dashboard } from '../dashboard/dashboard';
import { AccountList } from '../account/account-list';
import { EventList } from '../data/data-list';

const PortalView = ({ portal }) => {
	const action = useActions();
	const tabs = useTabs();
	const user = useAccount();
	const [content, setContent] = React.useState();
	
	React.useEffect(() => {
		if (portal) {
			tabs.define('Dashboard', () => <Dashboard portal={ portal } />);
			tabs.define('Accounts', () => <AccountList portal={ portal } />);
			tabs.define('Data', () => <EventList portal={ portal } />);
		}
		setContent(tabs.render());
	}, [portal, tabs]);
	
	if (portal) {		
		return (
			<div>
				<div className="d-flex justify-content-between align-items-center p-2 px-3 bg-dark text-light">
					<div className="d-flex align-items-center"><span>{ portal.name }</span><button className="btn btn-sm btn-primary ms-2" onClick={ () => action({ action:'portal-selector', payload: {forced: true} }) }>Change</button></div>
					<div className="text-right">
						<If condition={ user && user.details() }>
							Hello, {user.details().name}!
						</If>
						<a className="btn btn-link text-light" href="/logout"><FontAwesomeIcon icon="fa-solid fa-right-from-bracket" /></a>
					</div>
				</div>
				<div className="p-3">
				{ content }
				</div>
			</div>
		);
	}
	
	return [];
};
export { PortalView };