import React from 'react';
import { useAccount, useActions, useContent, useDialog, useStoredState } from '../../common/minifield';

import { PortalView } from './portal-view';
import { PortalList } from './portal-list';
import { MainPage } from '../main-page';

const PortalActions = () => {
	const account = useAccount();
	const action = useActions();
	const content = useContent();
	const dialog = useDialog();
	const [selectedPortal, setSelectedPortal] = useStoredState('portal');

	action.register('portal-selector', ({ forced }) => {
		account.secured((user) => {
			if (forced) {
				dialog({
					title: 'Choose a portal',
					content: <PortalList />,
					buttons: []
				})
			} else {
				if (selectedPortal) {					
					action({ action: 'portal-view', payload: { portal: selectedPortal } });
				} else {
					content({
						title: '',
						content: <MainPage><div className="h3 text-center mb-3">Choose a portal</div><PortalList /></MainPage>
					});
				}
			}
		}, {
			action: 'portal-selector'
		});
	});
	
	action.register('portal-view', ({ portal }) => {
		dialog({ options: { visible: false } })
		if (portal === undefined) {
			action({ action: 'portal-selector' });
		} else {
			content({ content: <PortalView portal={ portal } /> })
		}
		setSelectedPortal(portal);
	});
	
	return [];
};
export { PortalActions };