import React from 'react';
import List from '../../../common/components/list';
import { Spinner } from '../../../common/components/spinner';
import { useDataCache } from '../../data/data-cache';

const lastBookings = {
	attributes: {
		className: 'list-group list-group-flush'
	},
	type: 'list',
	components: [{
		attributes: {
			className: 'list-group-item d-flex justify-content-between align-items-center'
		},
		type: 'group',
		components: [{
			type: 'text',
			properties: {
				markdown: '**{{account.name}}**\n\n{{target.properties.intervalFmt}} / {{category}}\n\n{{status}} - {{modificationDate}}'
			}
		},{
			attributes: {
				className: 'btn btn-sm btn-primary ms-2'					
			},
			type: 'button',
			properties: {
				caption: 'View',
				action: 'data-view',
				payload: {
					namespace: '{{namespace}}',
					type: '{{type}}',
					id: '{{id}}'
				}
			}
		}]
	}],
	properties: {
		emptyMessage: {
			attributes: {
				className: 'list-group list-group-flush'
			},			
			type: 'group',
			components: [{
				attributes: {
					className: 'list-group-item'
				},
				type: 'text',
				properties: {
					text: 'No bookings found'
				}
			}]
		}		
	}
};
const LastBookings = ({ id = 'booking#all', namespace, pagination }) => {
	const bookings = useDataCache({ id, namespace, type: 'booking~target', pagination });
	
	if (!bookings) {
		return <Spinner className="mt-3" />;
	}
	
	return (
		<List component={ lastBookings } data={ bookings } />
	)
};
export { LastBookings };