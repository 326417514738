import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useActions, useContent } from '../../common/minifield';

const PasswordResetHandler = () => {
	const action = useActions();
	const content = useContent();
	const [parameters] = useSearchParams();
	
	React.useEffect(() => {
		const request = parameters.get('q');
		if (request) {
			action({ action: 'password-reset', payload: { request } });
		} else {
			action({ action: 'password-reset-request' });
		}
	});
	
	content({ path: '/' });
	
	return [];
};
export { PasswordResetHandler };