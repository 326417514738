

const DataFilter = ({ onChange }) => {
	return (
		<div className="border rounded p-2 mt-3">
			<form onSubmit={ onChange }>
				<div className="form-group">
					<label className="form-label">Type</label>
					<input type="text" className="form-control" value="Event" disabled readOnly />
				</div>
			</form>
		</div>
	);
};
export { DataFilter };