import React from 'react';
import { useApi, useDialog } from '../../common/minifield';
import { AccountView } from './account-view';

const AccountViewer = ({ namespace, badge }) => {
	const [account, setAccount] = React.useState();
	const api = useApi();
	const dialog = useDialog();
	
	React.useEffect(() => {		
		api.get('/admin/account/view', { namespace, badge }).then(result => setAccount(result));
	}, [api, namespace, badge]);
	
	React.useEffect(() => {
		if (account) {
			dialog({ title: account.name, options: { size: 'xl' } });
		}
	}, [account, dialog]);
	
	return (
		<AccountView account={ account } />
	);
};
export { AccountViewer };