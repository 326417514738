import ComponentRegistry from './component-registry';

import Group, { GroupType } from './components/group';
import Text, { TextType } from './components/text';
import Link, { LinkType } from './components/link';
import Button, { ButtonType } from './components/button';
import Media, { MediaType } from './components/media';
import List, { ListType } from './components/list';

ComponentRegistry.register(GroupType, (component, id, variables) => <Group id={id} component={component} key={id} variables={ variables } />);
ComponentRegistry.register(TextType, (component, id, variables) => <Text id={id} component={component} key={id} variables={ variables } />);
ComponentRegistry.register(LinkType, (component, id, variables) => <Link id={id} component={component} key={id} variables={ variables } />);
ComponentRegistry.register(ButtonType, (component, id, variables) => <Button id={id} component={component} key={id} variables={ variables } /> );
ComponentRegistry.register(MediaType, (component, id, variables) => <Media id={id} component={component} key={id} variables={ variables } /> );
ComponentRegistry.register(ListType, (component, id, variables) => <List id={id} component={component} key={id} /> );