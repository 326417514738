import React from 'react';

import { useActions } from '../../common/minifield';

const PortalSelectorHandler = () => {
	const action = useActions();

	React.useEffect(() => {
		action({ action: 'portal-selector' });
	});
	
	return [];
};
export { PortalSelectorHandler };