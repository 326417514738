import './common/component-loader';

import { Api } from './common/context/api-context'; 
import Dialog from './common/components/dialog';
import Content from './common/components/content';
import ActionHandler from './common/tools/actions';
import { AdminPortalContent } from './admin/specific-content';
import { useEnvironment } from './common/minifield';
import { Notification } from './common/tools/notifications';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas, fab)

const MinifieldAdmin = () => {
	const environment = useEnvironment();
	
	if (!environment.apiKey) {
        return [];
    }
	
	return (
		<>
			<ActionHandler />
			<Notification />
			<Api apiUrl={ environment.server } apiKey={ environment.apiKey } >
				<Dialog />
				<Content />
				<AdminPortalContent />
			</Api>
		</>
	);
}
export default MinifieldAdmin;
