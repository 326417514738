import React from 'react';
import base64 from 'react-native-base64';

import { useApi, useData, useEditedState } from '../../minifield';
import Button from '../../components/button';
import List from '../../components/list';
import Separator from '../../components/separator';
import { If } from '../../tools/jsx-helper';

const registrationBtn = {
	attributes: {
		className: 'btn btn-link text-dark'
	},
	type: 'button',
	properties: {
		action: 'registration',
		caption: 'Create an account'
	}
};

const forgotPasswordBtn = {
	attributes: {
		className: 'btn btn-link text-dark p-0 my-2',
		type: 'button',
		style: {
			fontSize: '80%'
		}
	},
	type: 'button',
	properties: {
		action: 'password-reset-request',
		caption: 'Forgot password?'
	}
};

const authButtons = {
	type: 'list',
	components: [{
		attributes: {
			className: 'btn mb-1 w-100 btn-light border border-dark'
		},
		type: 'button',
		components: [{
			attributes: {
				className: 'd-flex justify-content-center'
			},
			type: 'group',
			components: [{
				attributes: {
					className: 'me-2',
					style: {
						width: '1rem'
					}
				},
				type: 'media',
				properties: {
					dataURI: '{{{icon}}}'
				}
			}, {
				type: 'text',
				properties: {
					text: '{{caption}}'
				}
			}],
		}],
		properties: {
			action: 'redirect',
			payload: {
				target: '{{{loginUrl}}}'
			}
		}
	}]
};

const LoginForm = ({ action, payload }) => {
	const api = useApi();
	const dataProvider = useData();
	const [formData, , handler] = useEditedState({});
	const [authProviders, setAuthProviders] = React.useState([]);
	const refUsername = React.createRef();
	const refPassword = React.createRef();
	
	const errorHandler = (error) => {
		refUsername.current?.classList.add('is-invalid')
		refPassword.current?.classList.add('is-invalid')
	};
	
	const authLink = '/auth/' + base64.encode(JSON.stringify({ action, payload }));
	
	const login = (event) => {
		event.preventDefault();

		if (formData['email'] && formData['password']) {
			api.post('/account/login', formData, errorHandler).then(result => {
				if (result && result.status === 'OK') {
					document.location.href = authLink + '?token=' + result.body.token;
				} else {
					errorHandler();
				}
			});
		} else {
			errorHandler();
		}
	};
	
	React.useEffect(() => {
		dataProvider.list('[ref: /data/auth]').then(providers => {
			providers.forEach(item => {
				item.properties.loginUrl = api.url('/account/authorize/' + item.id, {redirect: authLink})
			});
			setAuthProviders(providers);
		}).catch(() => {
			setAuthProviders([]);
		});
	}, [api, authLink, dataProvider]);
	
	return (
		<>
			<div className="h3 text-center mb-3">Sign in to your account</div>
			<div className="d-flex flex-column">
				<If condition={ authProviders && authProviders.length > 0 }>
					<List component={ authButtons } data={ authProviders } />
					<Separator text='or' />
				</If>
				<div className="col d-flex justify-content-center">
					<form className="w-100" onSubmit={ login }>
						<div className="form-group mb-2">
							<label className="form-label">Email address</label>
							<input type="text" className="form-control" id="email" name="email" placeholder="Enter your email" autoComplete="username" onChange={ handler } ref={ refUsername } />
							<div className="invalid-feedback" style={ { fontSize: '60%'} }>Your username or password is incorrect</div>
						</div>
						<div className="form-group">
							<label className="form-label">Password</label>
							<input type="password" className="form-control" id="password" name="password" placeholder="Password" autoComplete="current-password" onChange={ handler } ref={ refPassword } />
							<div className="invalid-feedback" style={ { fontSize: '60%'} }>Your username or password is incorrect</div>
							<div className="text-left">
								<Button component={ forgotPasswordBtn }/>
							</div>
						</div>
						<button type="submit" className="btn btn-outline-dark w-100 mb-2">Sign in</button>
					</form>
				</div>
				<div className="text-center">
					<Button component={ registrationBtn } />
				</div>
			</div>
		</>
	);
};
export { LoginForm };