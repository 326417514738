import { useAccount, useActions } from '../minifield';

const LogoutHandler = () => {
	const account = useAccount();
	const action = useActions();
	
	account.logout();
	action({ action: 'redirect', payload: { target: '/' } });
	
	return [];
};
export { LogoutHandler };