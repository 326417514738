import React from 'react';

import { useApi, useCache } from '../../common/minifield';
import { useAccountCache } from '../account/account-cache';

const useDataCache = ({ id, namespace, type, pagination = {}, filter = {} }) => {
	const api = useApi();
	const cache = useCache(id ?? namespace + '/data/' + type);
	const accounts = useAccountCache({ id: namespace + '/accounts#all', namespace });
	const [data, setData] = React.useState();

	React.useEffect(() => {
		(async () => {
			if (namespace && type) {
				setData(cache() ?? cache(await api.get('/admin/data/list', {
					namespace, type, ...pagination, ...filter
				})));
			}
		})();
	}, [api, filter, namespace, pagination, type]);
	
	React.useEffect(() => {
		if (data && accounts && Array.isArray(data) && Array.isArray(accounts)) {
			data.forEach(item => {
				if ('badge' in item) {
					item.account = accounts.find(account => account.badge === item.badge);
				}
			});
		}
	}, [data, accounts]);
	
	return data;
};
export { useDataCache };