import MinifieldLogo from '../minifield-logo';
import img1 from '../image/background/benjamin-davies-Zm2n2O7Fph4-unsplash.webp';
import img2 from '../image/background/timothy-eberly-XemjjFd_4qE-unsplash.webp';
import img3 from '../image/background/tomasz-filipek-CUWC-6MRcNg-unsplash.webp';
import img4 from '../image/background/frances-gunn-QcBAZ7VREHQ-unsplash.webp';
import img5 from '../image/background/federico-respini-sYffw0LNr7s-unsplash.webp';
import img6 from '../image/background/grass-field-with-cows-distant-sunny-day-with-trees-blue-sky.webp';
import img7 from '../image/background/beautiful-shot-sheep-grazing-green-fields-during-sunset.webp';
import img8 from '../image/background/back-view-woman-shepherd-field.webp';

const MainPage = ({ children }) => {
	return (
		<Gallery images={ [img1, img2, img3, img4, img5, img6, img7, img8] }>
			<div className="container">
				<div className="bg-glass col-12 col-md-6 col-lg-5 col-xl-4 mx-auto p-3 rounded mt-5">
					<MinifieldLogo />
					<div className="mt-4">
					{ children }
					</div>
				</div>
			</div>
		</Gallery>
	);
};
export { MainPage };

const Gallery = ({ children, images }) => {
	const idx = Math.floor(Math.random() * images?.length);
	return (
		<div className="bg-image" style={{ backgroundImage: 'url(' + images[idx] + ')' }}>
			{ children }
		</div>
	);
};