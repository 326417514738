import React from 'react';
import moment from 'moment';
import List from '../../../common/components/list';
import { Spinner } from '../../../common/components/spinner';
import { usePropertyComparator } from '../../../common/tools/data-helper';
import { useDataCache } from '../../data/data-cache';

const upcomingEvents = {
	attributes: {
		className: 'list-group list-group-flush'
	},
	type: 'list',
	components: [{
		attributes: {
			className: 'list-group-item d-flex justify-content-between align-items-center'
		},
		type: 'group',
		components: [{
			attributes: {
				className: 'w-100'
			},
			type: 'text',
			properties: {
				html: '<p><strong>{{intervalFmt}}</strong> <span class="badge bg-secondary">{{category}}</span></p>' +
						'<div class="progress mt-2" style="--bs-progress-height: 1.3rem">' +
						  '<div class="progress-bar {{progressColor}} text-white" aria-valuenow="{{progress}}" aria-valuemin="0" aria-valuemax="100" style="width: {{progress}}%;">{{participants.length}}/{{limit}}</div>' +
						'</div>' +
					  '<p>{{intervalFmt}}</p>'
			}
		},{
			attributes: {
				className: 'btn btn-sm btn-primary ms-2'					
			},
			type: 'button',
			properties: {
				caption: 'View',
				action: 'data-view',
				payload: {
					namespace: '{{namespace}}',
					type: '{{type}}',
					id: '{{id}}'
				}
			}
		}]
	}],
	properties: {
		emptyMessage: {
			attributes: {
				className: 'list-group list-group-flush'
			},			
			type: 'group',
			components: [{
				attributes: {
					className: 'list-group-item'
				},
				type: 'text',
				properties: {
					text: 'No events found'
				}
			}]
		}
	}
};
const UpcomingEvents = ({ id = 'event#all', namespace, pagination, size }) => {
	const events = useDataCache({ id, namespace, type: 'event', pagination });
	const comparator = usePropertyComparator({ field: 'start', type: 'date' });

	React.useEffect(() => {
		if (Array.isArray(events)) {
			events.forEach(item => {
				if (Array.isArray(item.properties.participants) && item.properties.participants.length > 0) {
					item.progress = Math.min(item.properties.participants.length / item.properties.limit, 1) * 100;
					item.progressColor = item.properties.participants.length >= item.properties.limit ? 'bg-warning' : 'bg-primary';					
				} else {
					item.participants = [];
					item.progress = 0;					
				}
			});
		}
	}, [events]);
	
	if (!events) {
		return <Spinner className="mt-3" />;
	}
	
	return (
		<List component={ upcomingEvents } data={ events.filter(x => moment(x.properties.start).isAfter(moment())).sort(comparator) } size={ size } />
	)
};
export { UpcomingEvents };