import base64 from 'react-native-base64';

const encode = (str) => {
	return base64.encode(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};
export { encode };

const decode = (b64) => {
	return base64.decode((b64 + '===').slice(0, b64.length + (b64.length % 4)).replace(/-/g, '+').replace(/_/g, '/'))
};
export { decode };