const messenger = (() => {
	const topics = {};
	return {
		getTopic: (topicName) => {
			let topic = topics[topicName];
			if (topic === undefined) {
				const subscriptions = {};
				topic = {
					subscribe: (name, handler) => subscriptions[name] = handler,
					publish: (message) => Object.entries(subscriptions).forEach(([name, handler]) => {
						handler(message);
					})
				};
				topics[topicName] = topic;
			}
			return topic;
		}
	}
})();
export default messenger;