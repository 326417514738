import React from 'react';

import { useDialog, useTabs } from '../../common/minifield';
import { If } from '../../common/tools/jsx-helper';
import { UserDataList } from '../data/data-list';

const booking = {
	type: 'list',
	components: [{
		attributes: {
			className: 'p-2 border rounded mb-1 d-flex justify-content-between align-items-center'
		},
		type: 'group',
		components: [{
			type: 'text',
			properties: {
				markdown: '**{{target.properties.title}}**\n\n{{target.properties.intervalFmt}}\n\n{{status}} - {{modificationDate}}'
			}
		}, {
			attributes: {
				className: 'btn btn-sm btn-primary'
			},
			type: 'button',
			properties: {
				caption: 'View',
				action: 'data-view',
				payload: {
					namespace: '{{namespace}}',
					type: '{{type}}',
					id: '{{id}}'
				}
			}
		}]
	}]
};

const ticket = {
	type: 'list',
	components: [{
		attributes: {
			className: 'p-2 border rounded mb-1 d-flex justify-content-between align-items-center'
		},
		type: 'group',
		components: [{
			type: 'text',
			properties: {
				markdown: '{{#payment}}**{{payment.properties.product.properties.name}}**{{/payment}}{{^payment}}**Free**{{/payment}}\n\n{{status}} - {{creationDate}}'
			}
		}, {
			attributes: {
				className: 'btn btn-sm btn-primary'
			},
			type: 'button',
			properties: {
				caption: 'View',
				action: 'data-view',
				payload: {
					namespace: '{{namespace}}',
					type: '{{type}}',
					id: '{{id}}'
				}				
			}
		}]
	}]
};

const payment = {
	type: 'list',
	components: [{
		attributes: {
			className: 'p-2 border rounded mb-1 d-flex justify-content-between align-items-center'
		},
		type: 'group',
		components: [{
			type: 'text',
			properties: {
				markdown: '**{{product.properties.name}}**\n\n{{status}} - {{creationDate}}'
			}
		}, {
			attributes: {
				className: 'btn btn-sm btn-primary'
			},
			type: 'button',
			properties: {
				caption: 'View',
				action: 'data-view',
				payload: {
					namespace: '{{namespace}}',
					type: '{{type}}',
					id: '{{id}}'
				}				
			}
		}]
	}]
};

const AccountDetails = ({ account }) => {
	return (
		<div>
			<div className="form-group">
				<label className="form-label mb-1">Namespace</label>
				<input className="form-control" name="namespace" value={ account.namespace } readOnly />
			</div>
			<div className="form-group mt-2">
				<label className="form-label mb-1">Name</label>
				<input className="form-control" name="name" value={ account.name } readOnly />
			</div>
			
			<div className="form-group mt-2">
				<label className="form-label mb-1">Email</label>
				<input className="form-control" name="name" value={ account.email } readOnly />
			</div>
		</div>
	);
};

const AccountView = ({ account }) => {
	const dialog = useDialog();
	const tabs = useTabs();
	
	tabs.define('Overview', () => <div className="pt-3"><If condition={ account }><AccountDetails account={ account } /></If></div>);
	tabs.define('Bookings', () => <div className="pt-3"><If condition={ account }><UserDataList account={ account } type="booking~target" component={ booking } pagination={ { page: 0, size: 100, sort: 'creationDate,desc' } } /></If></div>);
	tabs.define('Tickets', () => <div className="pt-3"><If condition={ account }><UserDataList account={ account } type="ticket~payment|product" component={ ticket } pagination={ { page: 0, size: 100, sort: 'creationDate,desc' } } /></If></div>);
	tabs.define('Payments', () => <div className="pt-3"><If condition={ account }><UserDataList account={ account } type="payment~product" component={ payment } pagination={ { page: 0, size: 100, sort: 'creationDate,desc' } } /></If></div>);
	
	return (
		<div>
			{ tabs.render() }
			<div className="d-flex justify-content-end mt-3">
				<button type="button" className="btn btn-dark" onClick={ (e) => dialog({ options: { size: 'xl', visible: false } }) }>Cancel</button>
			</div>
		</div>
	);
};
export { AccountView };