import ReactGA from 'react-ga4';
import { useTopic } from '../minifield';

const ActionHandler = () => {
	const { subscribe } = useTopic('action');
	subscribe('ActionHandler', (message) => {
		const action = ActionMap.get(message.action);
		if (action) {
			console.log('Action: ' + JSON.stringify(message));
			ReactGA.event('mf_' + message.action);
			action(message.payload || {});
		}
	});
	return [];
};
export default ActionHandler;

const ActionMap = (() => {
	const actions = {};
	return {
		get: (actionName) => {
			return actions[actionName];
		},
		put: (actionName, action) => {
			actions[actionName] = action;
			return action;
		},
		remove: (actionName) => delete actions[actionName]
	}
})();
export { ActionMap };