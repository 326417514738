import React from 'react';

import { useApi } from '../../common/minifield';
import List from '../../common/components/list';
import { usePropertyComparator } from '../../common/tools/data-helper';

const component = {
	type: 'list',
	components: [{
		type: 'group',
		attributes: {
			className: 'border rounded p-2 mb-1 d-flex justify-content-between align-items-center'
		},
		components: [
			{
				type: 'text',
				properties: {
					markdown: '__{{name}}__\n\n{{domain}}'
				}
			},
			{
				type: 'button',
				attributes: {							
					className: 'btn btn-sm btn-primary'
				},
				properties: {
					caption: 'Select',
					action: 'portal-view',
					payload: {
						portal: {
							id: '{{id}}',
							namespace: '{{namespace}}',
							name: '{{name}}'
						}
					}
				}
			}
		]
	}]
};

const PortalList = () => {
	const api = useApi();
	const [portals, setPortals] = React.useState();
	const comparator = usePropertyComparator({ type: 'text', field: 'name' });
	
	React.useEffect(() => {
		api.get('/admin/portal/list').then(result => {
			if (result && Array.isArray(result)) {
				setPortals(result.map((portal) => {
					portal.properties = {
						id: portal.id,
						name: portal.name,
						domain: portal.domain,
						namespace: portal.namespace
					};
					return portal;
				}).sort(comparator));
			}
		}).catch(() => setPortals([]));
	}, [api, comparator]);
	
	return (
		<List component={ component } data={ portals } />
	);
};
export { PortalList };