import React from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { useAccount, useActions } from '../minifield';
import { decode } from '../tools/b64';

const AuthenticationHandler = () => {
	const account = useAccount();
	const action = useActions();
	const [parameters] = useSearchParams();
	const location = useLocation();
	
	React.useEffect(() => {
		const token = parameters.get('token');		
		if (token) {
			account.login(token, () => action(JSON.parse(decode(location.pathname.substring(6)))));
		} else {
			action({ action: 'login' })
		}
	}, [action, account, parameters, location]);
	
	return <Navigate to="/" />;
};
export { AuthenticationHandler };