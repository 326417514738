import React from 'react';
import Nav from 'react-bootstrap/Nav';

const Tabs = ({ definition, selectedTab, attributes }) => {
	const tabKeys = Object.keys(definition);
	const [activeTab, setActiveTab] = React.useState();
	
	React.useEffect(() => {
		const keys = Object.keys(definition);
		if (!selectedTab && keys.length > 0) {
			setActiveTab(keys[0]);
		} else {
			setActiveTab(selectedTab);
		}
	}, [definition, selectedTab]);
	
	if (tabKeys.length === 0) {
		return [];
	}
	
	return (
		<>
			<Nav defaultActiveKey={ tabKeys[0] } activeKey={ activeTab } variant='pills' onSelect={ (key) => setActiveTab(key) } { ...attributes }>
			{
				tabKeys.map((key) => <Nav.Item key={ key }><Nav.Link eventKey={ key }>{ key }</Nav.Link></Nav.Item>)
			}
			</Nav>
			<div>
				{ definition[activeTab] ? definition[activeTab]() : [] }
			</div>
		</>
	);
};
export { Tabs };