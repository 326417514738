import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useActions, useDialog } from '../../common/minifield';

import { LastBookings } from './cards/last-bookings';
import { LastPayments } from './cards/last-payments';
import { UpcomingEvents } from './cards/upcoming-events';
import { Tickets } from './cards/tickets';

const Dashboard = ({ portal }) => {
	const action = useActions();
	const dialog = useDialog();
	
	action.register("card-expand", ({ title, card }) => {
		dialog({
			title,
			content: card,
			buttons: [{
				caption: 'Close',
				callback: (dialog) => dialog.hide()
			}]
		});
	});
	
	const expandEvents = () => action({ action: 'card-expand', payload: {
		title: 'Upcoming Events',
		card: <UpcomingEvents id="dashboard/events#25" namespace={ portal.namespace } pagination={ { page: 0, size: 100, sort: 'properties.start,desc'} } size="25" />
	}});
	
	const expandBookings = () => action({ action: 'card-expand', payload: {
		title: 'Last Bookings',
		card: <LastBookings id="dashboard/bookings#25" namespace={ portal.namespace } pagination={ { page: 0, size: 25, sort: 'modificationDate,desc'} } />
	}});
	
	const expandPayments = () => action({ action: 'card-expand', payload: {
		title: 'Last Payments',
		card: <LastPayments id="dashboard/payments#25" namespace={ portal.namespace } pagination={ { page: 0, size: 25, sort: 'creationDate,desc'} } />
	}});
	
	const expandTickets = () => action({ action: 'card-expand', payload: {
		title: 'Withheld Tickets',
		card: <Tickets id="dashboard/tickets#withheld-25" namespace={ portal.namespace } pagination={ { page: 0, size: 25, sort: 'modificationDate,desc'} } status="WITHHELD" />
	}});
	
	const expandAllTickets = () => action({ action: 'card-expand', payload: {
		title: 'Tickets',
		card: <Tickets id="dashboard/tickets#25" namespace={ portal.namespace } pagination={ { page: 0, size: 25, sort: 'modificationDate,desc'} } />
	}});
	
	return (
		<div className="row g-3 pt-3">
			<div className="col-12 col-md-6 col-lg-4 col-xl-3">
				<div className="card">
					<h4 className="card-header d-flex justify-content-between align-items-center"><span>Upcoming Events</span><button className="btn" onClick={ expandEvents }><FontAwesomeIcon icon="fa-solid fa-expand" /></button></h4>
					<UpcomingEvents namespace={ portal.namespace } pagination={ { page: 0, size: 100, sort: 'properties.start,desc'} } size="5" />
				</div>
			</div>
			<div className="col-12 col-md-6 col-lg-4 col-xl-3">
				<div className="card">
					<h4 className="card-header d-flex justify-content-between align-items-center"><span>Last Bookings</span><button className="btn" onClick={ expandBookings }><FontAwesomeIcon icon="fa-solid fa-expand" /></button></h4>
					<LastBookings namespace={ portal.namespace } pagination={ { page: 0, size: 5, sort: 'modificationDate,desc'} } />
				</div>
			</div>
			<div className="col-12 col-md-6 col-lg-4 col-xl-3">
				<div className="card">
					<h4 className="card-header d-flex justify-content-between align-items-center"><span>Last Payments</span><button className="btn" onClick={ expandPayments }><FontAwesomeIcon icon="fa-solid fa-expand" /></button></h4>
					<LastPayments namespace={ portal.namespace } pagination={ { page: 0, size: 5, sort: 'creationDate,desc'} } />
				</div>
			</div>
			<div className="col-12 col-md-6 col-lg-4 col-xl-3">
				<div className="card">
					<h4 className="card-header d-flex justify-content-between align-items-center"><span>Tickets</span><button className="btn" onClick={ expandAllTickets }><FontAwesomeIcon icon="fa-solid fa-expand" /></button></h4>
					<Tickets namespace={ portal.namespace } pagination={ { page: 0, size: 5, sort: 'modificationDate,desc'} } />
				</div>
			</div>	
			<div className="col-12 col-md-6 col-lg-4 col-xl-3">
				<div className="card">
					<h4 className="card-header d-flex justify-content-between align-items-center"><span>Withheld Tickets</span><button className="btn" onClick={ expandTickets }><FontAwesomeIcon icon="fa-solid fa-expand" /></button></h4>
					<Tickets namespace={ portal.namespace } pagination={ { page: 0, size: 5, sort: 'modificationDate,desc'} } status="WITHHELD" />
				</div>
			</div>			
		</div>
	);
};
export { Dashboard };