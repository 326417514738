import React from 'react';

const EnvironmentContext = React.createContext({});
export { EnvironmentContext };

const Environment = ({ config, children }) => {
	const [environment, setEnvironment] = React.useState({});
	
	React.useEffect(() => {
		fetch(config).then(result => result.json()).then(properties => {
			setEnvironment(properties);
		});
	}, [config]);
	
	return (
		<EnvironmentContext.Provider value={ environment }>
			{ children }
		</EnvironmentContext.Provider>
	);
};
export { Environment };