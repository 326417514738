import React from 'react';

import { generateId, useData } from '../minifield';
import ComponentRegistry from '../component-registry';
import { usePropertyComparator, usePropertyFilter } from '../tools/data-helper';
import { Spinner } from './spinner';

const DataItem = ({ component, id, variables }) => {
	const template = component.components[0];	
	return ComponentRegistry.getRenderer(template.type).renderer(template, id, { ...variables, id: id });
};

const prepareEmptyMessage = (message) => {
	if (typeof message === 'string') {
		return {
			type: 'group',
			components: [{
				type: 'text',
				properties: {
					markdown: message
				}
			}]
		};
	}
	return message;
};

export const ListType = 'list';
const List = ({ component, id, variables={}, data, orderBy={}, filter={}, size=-1, emptyMessage='Empty list' }) => {	
	const componentId = id ?? component.id ?? generateId(ListType);
	const attributes = { ...component.attributes };
	const [dataRef] = React.useState(component.properties?.dataRef);
	const [_data, setData] = React.useState();
	const dataProvider = useData();
	const comparator = usePropertyComparator(component.properties?.comparator ?? { field: component.properties?.orderBy } ?? orderBy);
	const _filter = usePropertyFilter(component.properties?.filter ?? filter);
	const _size = component.properties?.size ?? size;
	const _message = prepareEmptyMessage(component.properties?.emptyMessage ?? emptyMessage);
	const [loading, setLoading] = React.useState(true);
	
	React.useEffect(() => {
		if (dataRef) {
			dataProvider.list(dataRef).then(result => {
				console.log('Fetched ' + result.length + ' items');
				
				result = result.filter(_filter).sort(comparator);
				if (_size > 0) {
					result = result.slice(0, _size);
				}			
				setData(result);
				
				console.log('Displayed ' + result.length + ' items');
				setLoading(false);
			});
		} else if (Array.isArray(data)) {
			let _items = data;
			if (_items) {
				_items = _items.filter(_filter).sort(comparator);
				if (_size > 0) {
					_items = _items.slice(0, _size);
				}
			}
			setData(_items);
			setLoading(false);
		}
	}, [dataProvider, dataRef, data, comparator, _filter, _size]);

	if (loading || !Array.isArray(_data)) {
		return <Spinner className="my-3" />
	}

	return (
		<div id={ componentId } { ...attributes } data-size={ _data.length }>
		{
			_data.length > 0 ? _data.map((dataItem, index) => <DataItem key={dataItem.id} id={dataItem.id ?? generateId(componentId + '_data_' + index)} component={ component } variables={ { ...variables, ...dataItem, ...dataItem.properties } } />) : <DataItem component={ _message } variables={ {...variables} }/>
		}
		</div>
	);
};
export default List;