import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Group from './group';
import { If } from '../tools/jsx-helper';
import { useTopic, generateId } from '../minifield';

const Dialog = () => {
    const [actions, setActions] = React.useState([]);
    const [content, setContent] = React.useState([]);
	const {subscribe} = useTopic('dialog');
    const [title, setTitle] = React.useState('');
	const [_options, setOptions] = React.useState({
		closable: true,
		disabled: false,
		size: 'md',
		visible: false,
	});

    subscribe('Dialog', ({ title, content, buttons, options = {} }) => {
        if (content) {
            if ('title' in content) {
                setTitle(content.title);
            }
            if ('components' in content) {
                setContent(<Group component={ content.components } />);
            } else {
                setContent(content);
            }
        }
        if (title !== undefined) {
            setTitle(title);
        }
        if (buttons && Array.isArray(buttons)) {
            setActions(buttons);
        }
		setOptions({ closable: true, disabled: false, size: 'md', visible: true, ...options });
    });
	
	const changeOptions = (options) => {
		setOptions({ closable: true, disabled: false, size: 'md', visible: true, ...options });
	};

    const invoke = (action) => {
        return (event) => {
            changeOptions({ disabled: true });
            action.callback({
				hide: () => changeOptions({ visible: false }),
				title: (newTitle) => newTitle === undefined ? title : setTitle(newTitle),
				content: (newContent) => setContent(newContent),
				options: (newOptions) => changeOptions(newOptions)
			});
        };
    };
	
    return (
        <Modal show={ _options.visible } size={ _options.size } aria-label={ title } onHide={ () => changeOptions({ size: _options.size, visible: false }) } backdrop={ _options.closable ? true : 'static' } keyboard={ _options.closable }>
            <Modal.Header closeButton={ _options.closable }>
                <Modal.Title>{ title }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
				{ content }				
            </Modal.Body>
            <If condition={ actions.length > 0 }>
                <Modal.Footer>
                {
                    actions.map( (action, key) => <Button active={ false } className="mf-btn--dialog" disabled={ _options.disabled } key={ generateId(action, key) } variant={ action.color || 'outline-dark' } onClick={ invoke(action) }>{ action.caption }</Button> )
                }
                </Modal.Footer>
            </If>
        </Modal>
    )
};
export default Dialog;